/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveManifesto } from "@dataResolvers";
import { Manifesto as PageTemplate } from "@pageTemplates";

export const query = graphql`
query {
  craft {
    entry(section: "manifesto") {
      ... on Craft_manifesto_manifesto_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        manifestoSlide {
          ... on Craft_manifestoSlide_sectionLanding_BlockType {
            type: typeHandle
            uid
            heading
            keyword
            subhead
            color
          }
          ... on Craft_manifestoSlide_hero_BlockType {
            type: typeHandle
            uid
            heading
            keyword
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_manifestoSlide_intro_BlockType {
            type: typeHandle
            uid
            firstHeading
            secondHeading
            keywords {
              ... on Craft_keywords_TableRow {
                word
                color
              }
            }
            secondSectionKeyword {
              ... on Craft_secondSectionKeyword_TableRow {
                word
                color
              }
            }
            subhead
          }
          ... on Craft_manifestoSlide_largeText_BlockType {
            type: typeHandle
            uid
            heading
            color
            keyword
          }
          ... on Craft_manifestoSlide_twoColumns_BlockType {
            type: typeHandle
            uid
            leftColumn
            rightColumn
            keyword
          }
          ... on Craft_manifestoSlide_threeColumns_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            color
            keyword
            copy: manifestoCopy {
              ... on Craft_manifestoCopy_BlockType {
                leftColumn
                rightColumn
              }
            }
          }
          ... on Craft_manifestoSlide_twoColumnsWithPullQuote_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            pullQuote
            color
            keyword
          }
        }
        manifestoFooter {
          ... on Craft_manifestoFooter_contactUs_BlockType {
            type: typeHandle
            uid
            heading
            subheading
            contactLink {
              text
              url
            }
            workLink {
              text
              url
            }
          }
          ... on Craft_manifestoFooter_newsletter_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            buttonText
          }
          ... on Craft_manifestoFooter_share_BlockType {
            type: typeHandle
            uid
            heading
            subheading
            aboutUsLink {
              url
              text
            }
          }
        }
      }
    }
  }
}
`;

const previewQuery = `
query {
  craft {
    entry(section: "manifesto") {
      ... on Craft_manifesto_manifesto_Entry {
        #Meta
        title
        uid
        url
        slug
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        manifestoSlide {
          ... on Craft_manifestoSlide_sectionLanding_BlockType {
            type: typeHandle
            uid
            heading
            keyword
            subhead
            color
          }
          ... on Craft_manifestoSlide_hero_BlockType {
            type: typeHandle
            uid
            heading
            keyword
            image {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
          ... on Craft_manifestoSlide_intro_BlockType {
            type: typeHandle
            uid
            firstHeading
            secondHeading
            keywords {
              ... on Craft_keywords_TableRow {
                word
                color
              }
            }
            secondSectionKeyword {
              ... on Craft_secondSectionKeyword_TableRow {
                word
                color
              }
            }
            subhead
          }
          ... on Craft_manifestoSlide_largeText_BlockType {
            type: typeHandle
            uid
            heading
            color
            keyword
          }
          ... on Craft_manifestoSlide_twoColumns_BlockType {
            type: typeHandle
            uid
            leftColumn
            rightColumn
            keyword
          }
          ... on Craft_manifestoSlide_threeColumns_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            color
            keyword
            copy: manifestoCopy {
              ... on Craft_manifestoCopy_BlockType {
                leftColumn
                rightColumn
              }
            }
          }
          ... on Craft_manifestoSlide_twoColumnsWithPullQuote_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            pullQuote
            color
            keyword
          }
        }
        manifestoFooter {
          ... on Craft_manifestoFooter_contactUs_BlockType {
            type: typeHandle
            uid
            heading
            subheading
            contactLink {
              text
              url
            }
            workLink {
              text
              url
            }
          }
          ... on Craft_manifestoFooter_newsletter_BlockType {
            type: typeHandle
            uid
            heading
            subhead
            buttonText
          }
          ... on Craft_manifestoFooter_share_BlockType {
            type: typeHandle
            uid
            heading
            subheading
            aboutUsLink {
              url
              text
            }
          }
        }
      }
    }
  }
}
`;

const dataResolver = ({ craft }) => {
  return resolveManifesto(craft.entry);
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
